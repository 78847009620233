//
export const GroupTypeOptions = [
  "NONE",
  "AUTO",
  "SECOND",
  "MINUTE",
  "HOUR",
  "DAY",
  "WEEK",
  "MONTH",
  "YEAR",
];

//
export const RealTimeIntervalOptions = [
  24,
  72,
  120,
];

export const feedbackTypeId = "54480ce1-00eb-4179-a2b6-f74daa6b9100";
export const sknTypeId = "b02eae33-6d98-41ec-a9d2-63905277a358";
