<template>
  <div>
    <b-tab :title="$t('DEVICE.DEVICE_FIELDS')">
      <b-form-group
        v-for="field in fieldsData"
        :key="'field-' + field.id"
        :label="parseI18n(field.name) + ', ' + parseI18n(field.unit)"
        label-cols-lg="4"
        :label-for="'field-' + field.id"
      >
        <b-form-input
          :id="'field-' + field.id"
          v-model="deviceFieldsMap[field.id]"
          :state="deviceFieldsErrors.has(field.id) ? false : null"
          v-bind:disabled="readOnly"
        >
        </b-form-input>
      </b-form-group>
    </b-tab>
    <b-tab
      v-for="tab in compoundTabs"
      lazy
      :key="'tab' + tab.group + '-' + tab.index"
      :title="$t('DEVICE.'+tab.group.toUpperCase()) + ' ' + tab.index"
    >
      <b-form-group
        v-for="field in fieldsByGroup[tab.group][tab.index]"
        :key="tab.index + '-' + field.id"
        :label="
            parseI18n(field.name) + ', ' + parseI18n(field.unit)
        "
        label-cols-lg="4"
        :label-for="'field-' + tab.index + '-' + field.id"
      >
        <b-form-input
          :id="'field-' + tab.index + '-' + field.id"
          v-model="dto.compoundFields[tab.index][field.id]"
          :state="
            compoundFieldsErrors[tab.index] !== undefined &&
            compoundFieldsErrors[tab.index][field.id] !== undefined
              ? false
              : null
          "
          v-bind:disabled="readOnly"
        >
        </b-form-input>
      </b-form-group>
      <div style="float: left">
        <b-button variant="danger" @click="$emit('removeCompound', tab.index, tab.group)"
          >{{ $t("DEVICE.REMOVE_COMPOUND") }}
        </b-button>
      </div>
    </b-tab>
  </div>
</template>

<script>
import i18nService from "../../../core/services/i18n.service";

export default {
  props: {
    fieldsData: Object,
    dto: Object,
    readOnly: Boolean,
    deviceFieldsMap: Object,
    compoundTabs: Array,
  },
  data() {
    return {
      deviceFieldsErrors: new Set(),
      compoundFieldsErrors: {},
    };
  },
  computed: {
    fieldsByGroup() {
      return Object.values(this.fieldsData).reduce((acc, value) => {
        acc[value.compoundGroup] = acc[value.compoundGroup] || [];
        for(let i = 1; i < value.compoundCount; i++){
          acc[value.compoundGroup][i] = acc[value.compoundGroup][i] || [];
          acc[value.compoundGroup][i].push(value);
        }
        return acc;
      }, {});
    }
  },
  methods: {
    parseI18n(str) {
      if (!str) return "";
      const locale = i18nService.getActiveLanguage();
      const json = str.split("i18n")[1];
      return JSON.parse(json)[locale] || "";
    },
  },
};
</script>
