<template>
  <div>
    <b-card-body>
      <b-form-group
        v-for="field in basicFields"
        :key="'field-' + field.id"
        :label="parseI18n(field.name) + ', ' + parseI18n(field.unit)"
        label-cols-lg="4"
        :label-for="'field-' + field.id"
      >
        <b-form-input
          :id="'field-' + field.id"
          v-model="deviceFieldsMap[field.id]"
          :state="deviceFieldsErrors.has(field.id) ? false : null"
          v-bind:disabled="readOnly"
        >
        </b-form-input>
      </b-form-group>
    </b-card-body>
    <b-card
      v-for="(group, name) in groups"
      :key="name"
      :class="{ 'mb-2': true }"
      :title="'Группа параметров '"
    >
      <b-tabs pills>
        <b-tab title="Основное">
          <b-form-group
            v-for="field in group.fields"
            :key="'field-' + field.id"
            :label="parseI18n(field.name) + ', ' + parseI18n(field.unit)"
            label-cols-lg="4"
            :label-for="'field-' + field.id"
          >
            <b-form-input
              :id="'field-' + field.id"
              v-model="deviceFieldsMap[field.id]"
              :state="deviceFieldsErrors.has(field.id) ? false : null"
              v-bind:disabled="readOnly || field.unchangeable"
            >
            </b-form-input>
          </b-form-group>
        </b-tab>
        <b-tab
          v-for="tab in group.tabs"
          :title="serializeOneOption(name, tab)"
          :key="name + '-' + tab"
        >
          <b-form-group
            v-for="field in group.fields"
            :key="'field-' + tab + '-' + field.id"
            :label="parseI18n(field.name) + ', ' + parseI18n(field.unit)"
            label-cols-lg="4"
            :label-for="'field-' + tab + '-' + field.id"
          >
            <b-form-input
              v-if="(!field.unchangeable) || (dto.compoundFields[tab][field.id] != undefined)"
              :id="'field-' + tab + '-' + field.id"
              v-model="dto.compoundFields[tab][field.id]"
              :state="
                compoundFieldsErrors[tab] !== undefined &&
                compoundFieldsErrors[tab][field.id] !== undefined
                  ? false
                  : null
              "
              v-bind:disabled="readOnly || field.unchangeable"
            />
            <b-form-input
                v-else
                :id="'field-' + tab + '-' + field.id"
                :value="deviceFieldsMap[field.id]"
                disabled
            />
          </b-form-group>
          <div style="float: left">
            <b-button variant="danger" @click="removeCompound(tab, name)"
              >{{ $t("DEVICE.REMOVE_COMPOUND") }}
            </b-button>
          </div>
        </b-tab>
      </b-tabs>

      <div style="float: right">
        <b-button
          variant="secondary"
          v-if="!readOnly"
          @click="addCompoundClicked(name)"
          >{{ $t("DEVICE.ADD_COMPOUND") }}</b-button
        >
      </div>
    </b-card>
    <b-modal
      v-model="addCompoundModal"
      :title="$t('DEVICE.CHOOSE_COMPOUND')"
      @ok="addNewCompound"
      :ok-disabled="invalidCompoundNumber()"
    >
      <vue-typeahead-bootstrap
        @hit="newCompoundNumber = $event.value"
        :data="newCompoundOptions"
        :serializer="serializeCompoundOptions"
        :minMatchingChars="0"
        :show-on-focus="true"
        ref="compoundSelect"
        maxMatches="15"
      />
    </b-modal>
  </div>
</template>
<script>
import i18nService from "@/core/services/i18n.service";

export default {
  props: {
    fieldsData: Object,
    dto: Object,
    readOnly: Boolean,
    deviceFieldsMap: Object,
  },
  data() {
    return {
      deviceFieldsErrors: new Set(),
      compoundFieldsErrors: {},
      basicFields: [],
      groups: {},
      addCompoundModal: false,
      newCompoundNumber: null,
      newCompoundGroup: null,
      newCompoundOptions: [],
      maxOption: 0,
    };
  },

  created: function () {
    this.buildGroups();
  },

  watch: {
    dto() {
      this.buildGroups();
    },
  },

  methods: {
    buildGroups() {
      this.basicFields = [];
      this.groups = Object.values(this.fieldsData).reduce((acc, fd) => {
        if (!fd.compound) {
          this.basicFields.push(fd);
          return acc;
        }

        acc[fd.compoundGroup] = acc[fd.compoundGroup] || {
          fields: [],
          tabs: [],
        };

        for (const [index, cf] of Object.entries(this.dto.compoundFields)) {
          // find whether all the fields are unchangeable
          const areChangeable = Object.keys(cf).map(fid => !this.fieldsData[fid].unchangeable);
          if(areChangeable.filter(b => b).length == 0) continue;

          if (acc[fd.compoundGroup].tabs.includes(index)) continue;
          if (fd.id in cf) acc[fd.compoundGroup].tabs.push(index);
        }

        acc[fd.compoundGroup].fields.push(fd);
        return acc;
      }, {});
      this.basicFields.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    addNewCompound() {
      let fields = {};
      for (const dtf of this.groups[this.newCompoundGroup].fields) {
        fields[dtf.id] = this.deviceFieldsMap[dtf.id];
      }
      this.dto.compoundFields[this.newCompoundNumber] = fields;
      this.groups[this.newCompoundGroup].tabs.push(this.newCompoundNumber);
    },

    addCompoundClicked(group) {
      this.newCompoundGroup = group;
      this.newCompoundNumber = null;
      let options = [];
      const maxOptions = Math.max(
        ...this.groups[group].fields.map((el) => el.compoundCount)
      );
      this.maxOption = maxOptions;
      for (let i = 1; i <= maxOptions; i++) {
        if (this.groups[group].tabs.includes(i.toString())) continue;

        options.push({
          value: i.toString(),
          text: this.serializeOneOption(group, i),
        });
      }
      this.newCompoundOptions = options;
      this.addCompoundModal = true;
      this.$nextTick(() => {
        this.$refs.compoundSelect.isFocused = true;
      });
    },

    serializeCompoundOptions(item) {
      return item.text;
    },

    serializeOneOption(group, i) {
      let optionText = this.$t("DEVICE." + group.toUpperCase()) + " " + i;
      if (group == "span") {
        const gridnum = Math.floor((i - 1) / 15) + 1;
        const sensornum = ((i - 1) % 15) + 1;
        optionText = `${this.$t("DEVICE.GRIDNUM")} ${gridnum}, ${this.$t(
          "DEVICE.SENSORNUM"
        )} ${sensornum} (${gridnum}:${sensornum})`;
      }
      return optionText;
    },

    invalidCompoundNumber() {
      return this.newCompoundNumber == null;
    },

    removeCompound(index, group) {
      //console.log(group);
      this.$bvModal
        .msgBoxConfirm(this.$t("COMMON.ARE_YOU_SURE"))
        .then((value) => {
          if (value) {
            const idsByGroup = Object.values(this.groups[group].fields).map(
              (f) => f.id
            );
            for (const id of idsByGroup) {
              delete this.dto.compoundFields[index][id];
            }
            this.groups[group].tabs = this.groups[group].tabs.filter(
              (t) => t !== index
            );
          }
        });
    },

    parseI18n(str) {
      if (!str) return "";
      const locale = i18nService.getActiveLanguage();
      const json = str.split("i18n")[1];
      try {
        return JSON.parse(json)[locale] || "";
      } catch (e) {
        return str;
      }
    },
  },
};
</script>
