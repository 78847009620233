export const additional_devices = [
  {
    id: 1,
    name: 'NEW_DASHBOARD.BMVD_DVP_DVL', // "БМВД+ДВП и/или ДВЛ"
    imageUrl: `${window.location.origin}/media/devices/bmvd_dvp_dvl.svg`,
  },
  {
    id: 2,
    name: 'NEW_DASHBOARD.SOKOL_TDV', // "Сокол ТДВ"
    imageUrl: `${window.location.origin}/media/devices/sokol_tdv.svg`,
  },
  {
    id: 3,
    name: 'NEW_DASHBOARD.SOKOL_A', // "Сокол А"
    imageUrl: `${window.location.origin}/media/devices/sokol_a.svg`,
  },
  {
    id: 4,
    name: 'NEW_DASHBOARD.SOKOL_DS', // "Сокол ДС"
    imageUrl: `${window.location.origin}/media/devices/sokol_dr800.svg`,
  },
  {
    id: 5,
    name: 'NEW_DASHBOARD.SOKOL_DR800', // "Сокол ДР-800"
    imageUrl: `${window.location.origin}/media/devices/sokol_dr800.svg`,
  },
  {
    id: 6,
    name: 'NEW_DASHBOARD.SOKOL_PIR', // "Сокол ПИР"
    imageUrl: `${window.location.origin}/media/devices/sokol_pir.svg`,
  },
];
