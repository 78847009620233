<template>
  <div class="container mt-4">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="sensorNumber" class="form-label">{{
            $t("NEW_DASHBOARD.SENSOR_NUMBER")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.id"
            id="sensorNumber"
            :class="{ 'is-invalid': errors.id }"
          />
          <div v-if="errors.id" class="invalid-feedback">{{ errors.id }}</div>
        </div>

        <div class="col-md-6">
          <label for="sensorName" class="form-label">{{
            $t("NEW_DASHBOARD.SENSOR_NAME")
          }}</label>
          <input
            class="form-control"
            v-model="sensorDto.name"
            id="sensorName"
            :class="{ 'is-invalid': errors.name }"
          />
          <div v-if="errors.name" class="invalid-feedback">
            {{ errors.name }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="sensorVersion" class="form-label">{{
            $t("NEW_DASHBOARD.SENSOR_VERSION")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.sensorVersion"
            id="sensorVersion"
            :class="{ 'is-invalid': errors.sensorVersion }"
          />
          <div v-if="errors.sensorVersion" class="invalid-feedback">
            {{ errors.sensorVersion }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="time_period" class="form-label">{{
            $t("NEW_DASHBOARD.COMMUNICATION_PERIOD")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.time_period"
            id="time_period"
            :class="{ 'is-invalid': errors.time_period }"
          />
          <div v-if="errors.time_period" class="invalid-feedback">
            {{ errors.time_period }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="powerLineId" class="form-label">{{
            $t("NEW_DASHBOARD.POWER_LINE")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.powerLineId"
            :options="powerLineOptions"
            id="powerLineId"
            :class="{ 'is-invalid': errors.powerLineId }"
          ></b-form-select>
          <div v-if="errors.powerLineId" class="invalid-feedback">
            {{ errors.powerLineId }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="vertexId" class="form-label">{{
            $t("NEW_DASHBOARD.VERTEX_NUMBER")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.vertexId"
            id="vertexId"
            :class="{ 'is-invalid': errors.vertexId }"
          />
          <div v-if="errors.vertexId" class="invalid-feedback">
            {{ errors.vertexId }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="threshold" class="form-label">{{
            $t("NEW_DASHBOARD.THRESHOLD")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.countValue"
            id="threshold"
            :class="{ 'is-invalid': errors.countValue }"
          />
          <div v-if="errors.countValue" class="invalid-feedback">
            {{ errors.countValue }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="ustavka_L" class="form-label">{{
            $t("NEW_DASHBOARD.USTAVKA")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.ustavka_L"
            id="ustavka_L"
            :class="{ 'is-invalid': errors.ustavka_L }"
          />
          <div v-if="errors.ustavka_L" class="invalid-feedback">
            {{ errors.ustavka_L }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="gainDA1_A" class="form-label">{{
            $t("NEW_DASHBOARD.GAIN_DA1_A")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.gainDA1_A"
            :options="gainOptions"
            id="gainDA1_A"
            :class="{ 'is-invalid': errors.gainDA1_A }"
          ></b-form-select>
          <div v-if="errors.gainDA1_A" class="invalid-feedback">
            {{ errors.gainDA1_A }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="gainDA1_B" class="form-label">{{
            $t("NEW_DASHBOARD.GAIN_DA1_B")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.gainDA1_B"
            :options="gainOptions"
            id="gainDA1_B"
            :class="{ 'is-invalid': errors.gainDA1_B }"
          ></b-form-select>
          <div v-if="errors.gainDA1_B" class="invalid-feedback">
            {{ errors.gainDA1_B }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="gainDA2_A" class="form-label">{{
            $t("NEW_DASHBOARD.GAIN_DA2_A")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.gainDA2_A"
            :options="gainOptions"
            id="gainDA2_A"
            :class="{ 'is-invalid': errors.gainDA2_A }"
          ></b-form-select>
          <div v-if="errors.gainDA2_A" class="invalid-feedback">
            {{ errors.gainDA2_A }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="gainDA2_B" class="form-label">{{
            $t("NEW_DASHBOARD.GAIN_DA2_B")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.gainDA2_B"
            :options="gainOptions"
            id="gainDA2_B"
            :class="{ 'is-invalid': errors.gainDA2_B }"
          ></b-form-select>
          <div v-if="errors.gainDA2_B" class="invalid-feedback">
            {{ errors.gainDA2_B }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="porog_MCF" class="form-label">{{
            $t("NEW_DASHBOARD.MCF_THRESHOLD")
          }}</label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            v-model.number="sensorDto.porog_MCF"
            id="porog_MCF"
            :class="{ 'is-invalid': errors.porog_MCF }"
          />
          <div v-if="errors.porog_MCF" class="invalid-feedback">
            {{ errors.porog_MCF }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="porogArtInt" class="form-label">{{
            $t("NEW_DASHBOARD.ART_INT_THRESHOLD")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.porogArtInt"
            id="porogArtInt"
            :class="{ 'is-invalid': errors.porogArtInt }"
          />
          <div v-if="errors.porogArtInt" class="invalid-feedback">
            {{ errors.porogArtInt }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="zeroNumber" class="form-label">{{
            $t("NEW_DASHBOARD.LOGICAL_ZEROS")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.zeroNumber"
            id="zeroNumber"
            :class="{ 'is-invalid': errors.zeroNumber }"
          />
          <div v-if="errors.zeroNumber" class="invalid-feedback">
            {{ errors.zeroNumber }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="step" class="form-label">{{
            $t("NEW_DASHBOARD.TIME_STEP")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="sensorDto.step"
            id="step"
            :class="{ 'is-invalid': errors.step }"
          />
          <div v-if="errors.step" class="invalid-feedback">
            {{ errors.step }}
          </div>
        </div>

        <div class="col-md-6">
          <label for="isVoltageCircuit" class="form-label">{{
            $t("NEW_DASHBOARD.INPUT_CIRCUIT_TYPE")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.isVoltageCircuit"
            :options="[
              { value: 1, text: $t('NEW_DASHBOARD.VOLTAGE') },
              { value: 0, text: $t('NEW_DASHBOARD.CURRENT') },
            ]"
            id="isVoltageCircuit"
            :class="{ 'is-invalid': errors.isVoltageCircuit }"
          ></b-form-select>
          <div v-if="errors.isVoltageCircuit" class="invalid-feedback">
            {{ errors.isVoltageCircuit }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddScatDeviceForm",
  props: {
    sensorData: {
      type: Object,
      default: () => ({
        id: null,
        sensorVersion: null,
        time_period: 900,
        powerLineId: null,
        vertexId: null,
        ustavka_L: 15,
        gainDA1_A: 1,
        gainDA1_B: 1,
        gainDA2_A: 1,
        gainDA2_B: 1,
        porog_MCF: null,
        porogArtInt: null,
        zeroNumber: null,
        step: null,
        isVoltageCircuit: 1,
        countValue: null,
        name: null,
      }),
    },
    powerLines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      gainOptions: [
        { value: 1, text: "1x" },
        { value: 2, text: "2x" },
        { value: 4, text: "4x" },
      ],
    };
  },
  computed: {
    sensorDto() {
      return { ...this.sensorData };
    },
    powerLineOptions() {
      return (
        this.powerLines.map((powerLine) => ({
          value: powerLine.id,
          text: powerLine.name,
        })) || []
      );
    },
  },
  methods: {
    validateForm() {
      this.errors = {};

      if (!this.sensorDto.id) {
        this.errors.id = this.$t("NEW_DASHBOARD.SENSOR_NUMBER_REQUIRED");
      }

      if (!this.sensorDto.sensorVersion || this.sensorDto.sensorVersion < 400) {
        this.errors.sensorVersion = this.$t("NEW_DASHBOARD.VERSION_REQUIRED");
      }

      if (!this.sensorDto.name) {
        this.errors.name = this.$t("NEW_DASHBOARD.NAME_REQUIRED");
      }

      if (this.sensorDto.name && this.sensorDto.name.length > 50) {
        this.errors.name = this.$t("NEW_DASHBOARD.NAME_LENGTH");
      }

      if (
        !this.sensorDto.time_period ||
        this.sensorDto.time_period < 15 ||
        this.sensorDto.time_period > 1020
      ) {
        this.errors.time_period = this.$t(
          "NEW_DASHBOARD.COMMUNICATION_PERIOD_REQUIRED"
        );
      }

      if (!this.sensorDto.powerLineId) {
        this.errors.powerLineId = this.$t("NEW_DASHBOARD.POWER_LINE_REQUIRED");
      }

      if (!this.sensorDto.vertexId || this.sensorDto.vertexId > 9999) {
        this.errors.vertexId = this.$t("NEW_DASHBOARD.VERTEX_NUMBER_REQUIRED");
      }

      if (!this.sensorDto.countValue || this.sensorDto.countValue > 100) {
        this.errors.countValue = this.$t("NEW_DASHBOARD.THRESHOLD_REQUIRED");
      }

      if (this.sensorDto.ustavka_L < 0 || this.sensorDto.ustavka_L > 255) {
        this.errors.ustavka_L = this.$t("NEW_DASHBOARD.USTAVKA_REQUIRED");
      }

      if (!this.sensorDto.gainDA1_A) {
        this.errors.gainDA1_A = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA1_B) {
        this.errors.gainDA1_B = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA2_A) {
        this.errors.gainDA2_A = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA2_B) {
        this.errors.gainDA2_B = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.porog_MCF) {
        this.errors.porog_MCF = this.$t("NEW_DASHBOARD.MCF_THRESHOLD_REQUIRED");
      }

      if (!this.sensorDto.porogArtInt) {
        this.errors.porogArtInt = this.$t(
          "NEW_DASHBOARD.ART_INT_THRESHOLD_REQUIRED"
        );
      }

      if (!this.sensorDto.zeroNumber) {
        this.errors.zeroNumber = this.$t("NEW_DASHBOARD.LOGICAL_ZEROS_REQUIRED");
      }

      if (!this.sensorDto.step) {
        this.errors.step = this.$t("NEW_DASHBOARD.TIME_STEP_REQUIRED");
      }

      if (this.sensorDto.isVoltageCircuit === null) {
        this.errors.isVoltageCircuit = this.$t(
          "NEW_DASHBOARD.INPUT_CIRCUIT_REQUIRED"
        );
      }

      return Object.keys(this.errors).length === 0;
    },

    submitForm() {
      if (this.validateForm()) {
        this.$emit("submit", this.sensorDto);
      } else {
        console.log(this.$t("NEW_DASHBOARD.FORM_INVALID"));
      }
    },
  },
};
</script>

<style scoped>
.form-label {
  font-weight: bold;
}

.form-select,
.form-control {
  font-size: 1rem;
}

.btn-primary,
.btn-secondary {
  font-size: 1rem;
}

.invalid-feedback {
  font-size: 0.875rem;
}

.row.g-3 > [class*="col-"] {
  padding-top: 0.5rem;
}
</style>
